import React from 'react';
import {FooterProps} from "../../../types";
import './style.css';
import Image from "../../../components/Image";
import aetLogo from "../../../assets/images/Logo-aet.png";
import {Link} from "react-router-dom";
import {categoriesLinks, mainAppLinks} from "../../../constants/links";
import {CiMail} from "react-icons/ci";
import Button from "../../../components/Button";
import Separator from "../../../components/Separator";


const Footer: React.FC<FooterProps> = ({ copyright="Default copyright" }) => {
    return (
        <footer>
            <div className="content">
                <div className="about">
                    <h4>A props</h4>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                        <br/>
                        <br/>
                        <br/>
                        <b>Email :</b> aet-bf@aet.bf
                        <br/>
                        <b>Phone :</b> 0000000
                    </p>
                </div>
                <div className="page-links">
                    <h4>Lien rapides</h4>
                    <ul>
                        {mainAppLinks.map((menuItem, index) => (
                            <li key={index}>
                                <Link to={menuItem.url} className="footer-link-item">{menuItem.title}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="blog-categories">
                    <h4>Categories</h4>
                    <ul>
                        {categoriesLinks.map((category, index) => (
                            <li key={index}>
                                <Link to={category.url} className="footer-link-item">{category.title}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="newsletter">
                    <div className="newsletter-form-container">
                        <h4>Notre news letter</h4>
                        <p>Get articles and offers via email.</p>
                        <form className="newsletter-form">
                            <div className="input-with-icon">
                                <input type="text"/>
                                <CiMail color="#696A75" size="15"/>
                            </div>
                            <Button>
                                Subscribe
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
            <Separator/>
            <div className="legal">
                <Image src={aetLogo} alt={"Logo aet"} style={{height: "4em"}}/>
                <div className="info">
                    <ul>
                        <li>
                            <Link to='terms-of-use' className="footer-link-item">
                                Terms of Use
                            </Link>
                        </li>
                        <li>
                                <Link to='privacy-policy' className="footer-link-item">
                                    Privacy Policy
                                </Link>
                        </li>
                        <li>
                                <Link to='cookie-policy' className="footer-link-item" >
                                    Cookie Policy
                                </Link>
                        </li>

                    </ul>
                </div>
            </div>
            <p className="copy-right">&copy; {copyright}</p>
        </footer>
    );
};



export default Footer;
