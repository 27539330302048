import {Outlet, ScrollRestoration, useLocation} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import React, {useLayoutEffect} from "react";


const StoreLayout = ()=>{


    return (
        <>

            <Header title={"Store"}/>
            <main id={"main-app-content"}><Outlet/></main>
            <ScrollRestoration />
            <Footer/>
        </>
    )
}

export default StoreLayout;
