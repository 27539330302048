import React from 'react';
import {ButtonProps} from "../../types";
import './style.css';


const Button: React.FC<ButtonProps> = ({ className = '', onClick, children, style,disabled }) => {
    // Default button styles for width and height (you can adjust these values)

    const buttonStyles: React.CSSProperties = {
        ...style, // Apply any custom styles passed via props
    };

    return (
        <button className={`button ${className}`} style={buttonStyles} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};

export default Button;
